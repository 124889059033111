import React, { useContext } from "react";
import { Link } from "react-router-dom";

import BooksDB from "./BooksDB";
import { AppContext } from "./context/context";

const Books = (props) => {
  const bookInfo = BooksDB.books;
  const { setCurrentBook, currentBook } = useContext(AppContext);
  if (!bookInfo) {
    return <div></div>;
  }

  function setBook(thisbook) {
    localStorage.setItem("currentBook", JSON.stringify({ thisbook }));
    setCurrentBook(thisbook);
  }

  return (
    <div className="flex flex-col items-center m-3 md:m-0">
      <p className="text-black text-center text-5xl my-5">Books</p>
      {bookInfo?.map((book, i) => {
        return (
          <div
            key={book.title}
            className="paper-bg flex w-full flex-col items-center bg-yellow-800 rounded-lg shadow-md md:flex-row md:w-5/6 m-3 p-4"
          >
            <img
              className="object-contain w-full rounded-t-lg h-48 mb-4 md:mb-0 md:h-full md:w-48"
              src={book.photo}
              alt="lam-ang"
            />

            <div className="flex flex-col justify-between px-4 leading-normal">
              <h5 className="font-maragsa mb-2 text-2xl font-bold tracking-[3px] text-gray-900 dark:text-white">
                {book.title}
              </h5>
              <p className="serif mb-3 h-full font-normal overflow-auto text-base md:text-sm">
                {book.description}
              </p>
              <Link
                to={book.bookPage}
                onClick={() => setBook(book)}
                className="flex self-start items-center px-3 py-2 text-sm font-medium text-center text-white bg-black rounded-lg hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 font-maragsa tracking-wide uppercase"
              >
                Read more
              </Link>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Books;
