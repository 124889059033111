import { useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import ReviewsForm from "./ReviewsForm";
import emailjs, { sendForm } from "emailjs-com";
import { AppContext } from "./context/context";

export default function MyModal() {
  const { stars } = useContext(AppContext);

  const localBook = JSON.parse(localStorage.getItem("currentBook"));
  const currentBook = localBook.thisbook;
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_os7qius",
        "template_r7xhw0d",
        e.target,
        "xkwFCbIbsZ_-fvub9"
      )
      .then(
        (result) => {
          alert("Form Submitted!");
          closeModal();
        },
        (error) => {
          console.log("not sent");
        }
      );
    e.target.reset();
  };

  return (
    <div class="inline-block align-top ...">
      <div className="inset-0 flex items-center justify-center">
        <button
          type="button"
          onClick={openModal}
          className="px-6 py-2 box-content font-semibold rounded-xl border-yellow-600 border-2 hover:bg-yellow-600 text-white font-maragsa uppercase tracking-widest"
        >
          Add Review
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Leave a Review for {currentBook.title}
                  </Dialog.Title>

                  <div className="mt-2">
                    <form className="space-y-8" onSubmit={sendEmail}>
                      <input
                        name="title"
                        className="hidden"
                        value={currentBook.title}
                      />
                      <input name="rating" className="hidden" value={stars} />
                      <div>
                        <label
                          for="email"
                          className="block mb-2 text-sm font-medium text-black"
                        >
                          Your email
                        </label>
                        <input
                          type="email"
                          name="email"
                          className="shadow-sm bg-white border border-gray-300 text-black text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-white0 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                          placeholder="name@alamat.com"
                          required
                        />
                      </div>

                      <div>
                        <label
                          for="subject"
                          className="block mb-2 text-sm font-medium text-black"
                        >
                          Name
                        </label>
                        <div className="flex justify-center">
                          <input
                            type="text"
                            name="firstName"
                            className="p-3 w-full mr-1 text-sm text-black bg-white rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-white0 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                            placeholder="First Name"
                            required
                          />
                          <input
                            type="text"
                            name="lastName"
                            className="p-3 w-full text-sm text-black bg-white rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-white0 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                            placeholder="Last Name"
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-2">
                        <label
                          for="message"
                          className="block mb-2 text-sm font-medium text-black"
                        >
                          Your message
                        </label>
                        <textarea
                          name="message"
                          rows="6"
                          className="block p-2.5 w-full text-sm text-black bg-white rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-white0 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="Leave a comment..."
                        ></textarea>
                      </div>

                      <ReviewsForm />

                      <div className="flex mt-4">
                        <button
                          type="submit"
                          className="mr-1 inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        >
                          Submit
                        </button>

                        <button
                          type="button"
                          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          onClick={closeModal}
                        >
                          Close
                        </button>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
