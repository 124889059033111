import React, { useContext } from "react";
import { AppContext } from "./context/context";
import pageFlip from "./assets/page-flip.png";

import MyBook from "./MapsBook";

const Maps = (props) => {
  const { bookNumber, setBookNumber } = useContext(AppContext);

  return (
    <div className="h-full flex flex-col items-center rattan-bg justify-center p-4">
      <div className="flex mb-4">
        <button
          className="text-center px-6 font-semibold rounded-xl bg-green-600 hover:bg-green-500 text-white px-4 py-2.5 mx-1 mt-1"
          onClick={() => setBookNumber(0)}
        >
          LamAng
        </button>

        <button
          className="text-center px-6 font-semibold rounded-xl bg-green-600 hover:bg-green-500 text-white px-4 py-2.5 mx-1 mt-1"
          onClick={() => setBookNumber(1)}
        >
          Lumalindaw
        </button>

        <button
          className="text-center px-6 font-semibold rounded-xl bg-green-600 hover:bg-green-500 text-white px-4 py-2.5 mx-1 mt-1"
          onClick={() => setBookNumber(2)}
        >
          Taguwasi
        </button>

        <button
          className="text-center px-6 font-semibold rounded-xl bg-green-600 hover:bg-green-500 text-white px-4 py-2.5 mx-1 mt-1"
          onClick={() => setBookNumber(3)}
        >
          Urduja
        </button>

        <button
          className="text-center px-6 font-semibold rounded-xl bg-green-600 hover:bg-green-500 text-white px-4 py-2.5 mx-1 mt-1"
          onClick={() => setBookNumber(4)}
        >
          Tala
        </button>

        <button
          className="text-center px-6 font-semibold rounded-xl bg-green-600 hover:bg-green-500 text-white px-4 py-2.5 mx-1 mt-1"
          onClick={() => setBookNumber(5)}
        >
          Ascension
        </button>
      </div>
      
      
      <MyBook />
    </div>
  );
};

export default Maps;
