import React, { useContext } from "react";
import { Navigation, Pagination, EffectFade, Parallax } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Books from "./BooksDB";

// Import Swiper styles
import "swiper/css/bundle";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { AppContext } from "./context/context";
import { Link } from "react-router-dom";

export default function Carousel() {
  const slides = Books.books;

  const { setCurrentBook } = useContext(AppContext);

  function setBook(thisbook) {
    localStorage.setItem("currentBook", JSON.stringify({ thisbook }));
    setCurrentBook(thisbook);
  }

  return (
    <>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        speed={600}
        parallax={true}
        loop={true}
        navigation={true}
        modules={[Parallax, Pagination, Navigation]}
        className="mySwiper"
      >
        <div
          slot="container-start"
          className="parallax-bg background-tint"
          data-swiper-parallax="-23%"
        />

        {slides.map((book, i) => {
          return (
            <SwiperSlide
              key={book.title}
              className="flex flex-col-reverse md:flex-row self-center justify-items-center"
            >
              <div className="md:w-1/2 text-center md:text-left md:p-4 self-center">
                <div
                  className="title font-maragsa text-4xl tracking-[3px] mb-2 mt-4"
                  data-swiper-parallax="-300"
                >
                  {book.title}
                </div>
                <div
                  className="text md:text-xl text-base mb-4"
                  data-swiper-parallax="-200"
                >
                  <p className="serif md:text-xl">{book.shortDescription}</p>
                </div>
                <div>
                  <a
                    href={book.link}
                    target="_blank"
                    className="md:text-base text-base mt-3 md:mt-3 mr-2 inline-block px-6 py-2.5 bg-red-600 hover:bg-red-400 text-white leading-tight uppercase rounded-full shadow-md cursor-pointer font-bold font-maragsa tracking-wide"
                    rel="noreferrer"
                  >
                    Buy Now
                  </a>

                  <Link
                    to={book.bookPage}
                    onClick={() => setBook(book)}
                    className="md:text-base text-base mt-3 md:mt-3 inline-block px-6 py-2.5 bg-blue-600 hover:bg-blue-400 text-white leading-tight uppercase rounded-full shadow-md cursor-pointer font-maragsa tracking-wide"
                  >
                    Read More
                  </Link>
                </div>
              </div>
              <div className="md:w-1/2" data-swiper-parallax="-100">
                <img
                  className="object-contain md:pt-8 mt-2 md:mt-0 w-1/2 md:w-full max-w-xs h-full mx-auto"
                  src={book.photo}
                  alt={book.title}
                />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}
