import React from "react";
import { Link } from "react-router-dom";
import AuthorPic from "./assets/AuthorPic.jpg";

const Author = (props) => {
  return (
    <div className="w-screen mx-0 bg-white p-4">
      <div className="md:flex">
        <div className="md:shrink-0 md:w-1/3">
          <img
            className="w-full md:h-full md:w-auto object-cover"
            src={AuthorPic}
            alt="Author Pic"
          />
        </div>
        <div className="aboutContent p-8">
          <div className="font-maragsa font-bold pb-2 text-3xl tracking-[3px] text-black uppercase">
            Herbert Deleon
          </div>
          <p>
            Herbert De Leon or Herbie as often called by his friends and family
            lived and grew up in Kawit, Cavite in the Philippines for the first
            thirty-two years of his life. Shortly after marrying Chieryl De Leon
            back in 2005, he migrated to the United States along with their
            daughter Caelin Anne.
          </p>

          <p>
            He was a Graphic Designer for different private companies in the
            earlier part of his career in the Philippines. But after migrating
            to the US, he took on a variety of jobs to make ends meet. He is
            currently working from home and is operating a family-owned
            business.
          </p>

          <p>
            He had always been a voracious book reader since he was a child,
            enamored with fantasy and mythology as he poured through their
            family's old and outdated encyclopedias. He devoured Greek and Norse
            mythology as well as everything else that caught his interest.
          </p>
          <p>
            As a young man, he elevated his reading habits to cover even deeper
            and more profound bits of literature which included poetry like
            Dante's Divine Comedy and more sophisticated fantasies like the
            works of JRR Tolkien. He even read the more popular but just as
            poignant stories by Chris Claremont in the X-Men comic books as well
            as the novels by Stephen King and Anne Rice.
          </p>
          <p>
            History is also a topic that captured Herbie's interest. He loves
            the stories about the great men and women that shaped the tale of
            humanity either through conquest or innovation.
          </p>
          <p>
            Herbie is still an avid reader, a casual RTS gamer, and a lover of
            great cinema.
          </p>

          <div className="flex flex-wrap justify-center md:justify-start gap-2 mt-8">
            <a
              href="https://www.facebook.com/Alamatbooks?mibextid=LQQJ4d"
              target="_blank"
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
              className="w-32 px-6 py-2.5 mb-2 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out flex items-center"
              style={{ backgroundColor: "#1877f2" }}
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 448 512"
                className="w-5 h-5 mr-0"
              >
                <path
                  fill="currentColor"
                  d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                />
              </svg>
              Facebook
            </a>

            <a
              href="https://www.instagram.com/alamat_books/"
              target="_blank"
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
              className="w-30 px-5 py-2.5 mb-2 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out flex items-center"
              style={{ backgroundColor: "#c13584" }}
              rel="noreferrer"
            >
            
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="45 0 448 512"
                className="w-5 h-5 mr-0"
              >
                <path
                  fill="currentColor"
                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                />
              </svg>
              Instagram
            </a>
            
            <a
              href="https://x.com/alamatbooks"
              target={"_blank"}
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
              className="w-32 px-6 py-2.5 mb-2 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out flex items-center"
              style={{ backgroundColor: "#000000" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="w-3.5 h-3.5 mr-2"
              >
                <path
                  fill="currentColor"
                  d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
                />
              </svg>
              <a className="px-6">X</a>
            </a>

            <a
              href="https://www.tiktok.com/@alamatbooks"
              target={"_blank"}
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
              className="w-32 px-6 py-2.5 mb-2 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out flex items-center"
              style={{ backgroundColor: "black" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="w-3.5 h-3.5 mr-2"
              >
                <path
                  fill="currentColor"
                  d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"
                />
              </svg>
              Tiktok
            </a>
          </div>
        </div>
      </div>
      <div className="bg-black"></div>
    </div>
  );
};

export default Author;
