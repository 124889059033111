import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import ReviewsDB from "./ReviewsDB";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

export default function App() {
  const localBook = JSON.parse(localStorage.getItem("currentBook"));
  const currentBook = localBook.thisbook;
  const reviewsIndex = currentBook.reviewIndex;
  const db = ReviewsDB.book;
  const bookReviews = db[reviewsIndex].reviews;

  return (
    <div>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        {bookReviews.length ? (
          bookReviews.map((reviews, i) => {
            return (
              <SwiperSlide key={i}>
                <article className="mx-6">
                  <div className="flex items-center mb-4 space-x-4">
                    <img
                      className="w-10 h-10 rounded-full"
                      src="https://smartkid.club/assets/images/default-user.png"
                      alt=""
                    />
                    <div className="space-y-1 font-medium text-black">
                      <p>
                        {reviews.name}{" "}
                        <time
                          dateTime="2014-08-16 19:00"
                          className="block text-sm text-gray-500 dark:text-gray-400"
                        >
                          Added on {bookReviews[i].date}
                        </time>
                      </p>
                    </div>
                  </div>
                  {reviews.rating}
                  <p className="mb-2 font-light text-gray-500 dark:text-gray-400">
                    {reviews.body}
                  </p>
                  <aside>
                    <p className="mt-1 text-xs text-gray-500 dark:text-gray-400"></p>
                    <div className="flex items-center mt-3 space-x-3 divide-x divide-gray-200 dark:divide-gray-600">
                      <button
                        onClick={() => console.log("helpful++")}
                        className="text-gray-900 border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-xs px-2 py-1.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                      >
                        Helpful
                      </button>
                      <button
                        onClick={() => {
                          alert("This has been flagged");
                        }}
                        className="pl-4 text-sm font-medium text-blue-600 hover:underline dark:text-blue-500"
                      >
                        Report abuse
                      </button>
                    </div>
                  </aside>
                </article>
              </SwiperSlide>
            );
          })
        ) : (
          <SwiperSlide>
            <p className="text-gray-500 text-center">No Reviews Yet</p>
          </SwiperSlide>
        )}
      </Swiper>
    </div>
  );
}
