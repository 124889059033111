import React from "react";
import Bg from "./assets/text-bg.png";
import Carousel from "./Carousel";

import { Link } from "react-router-dom";
import emailjs, { sendForm } from "emailjs-com";
import poster from "./assets/BookSigningAd.jpg"


const Home = () => {
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_os7qius",
        "template_89no7ko",
        e.target,
        "xkwFCbIbsZ_-fvub9"
      )
      .then(
        (result) => {
          alert("Subsription Successful!");
        },
        (error) => {
          console.log("not sent");
        }
      );
    e.target.reset();
  };

  return (
    <div className="flex flex-col h-full bg-white">
  
      
      
      <div className="h-2/3 md:flex bg-gray-400 py-0 relative">
        <Carousel />
      </div>


      <div
        id="newsletter"
        className="bg-white flex flex-1 items-center px-4 w-full"
      >
        <section className="my-4 md:my-0 mx-auto max-w-5xl text-gray-800 text-center lg:text-left w-full">
          <div className="grow-0 shrink-0 basis-auto w-full lg:w-10/12 px-3">
            <div className="flex flex-wrap items-center justify-around">
              <div className="w-full pt-4 md:w-1/2 md:pr-4">
                <h2 className="text-2xl md:text-3xl font-bold mb-4 md:mb-0 ">
                  Do not miss any updates.
                  <br />
                  <span className="text-2xl md:text-3xl text-yellow-500">
                    Subscribe to the newsletter.
                  </span>
                </h2>
              </div>
              <div className="md:mb-0 md:w-1/2 md:pl-4 w-full">
                <form onSubmit={sendEmail} className="md:flex flex-col my-2">
                  <input
                    type="text"
                    className="mb-1 form-control block w-full px-4 py-2 my-2 md:mb-0 md:mr-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    required
                    name="name"
                    placeholder="Enter your name"
                  />
                  <input
                    type="text"
                    className="form-control block w-full px-4 py-2 my-2 md:mb-0 md:mr-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    required
                    name="email"
                    placeholder="Enter your email"
                    pattern="[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  />
                  <button
                    type="submit"
                    className="my-2 inline-block px-7 py-3 bg-black text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg hover:bg-gray-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-black-800 active:shadow-lg transition duration-150 ease-in-out w-full font-maragsa tracking-wide"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                  >
                    Subscribe
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Home;
