import { useState } from "react";
import Logo from "./assets/main-logo.png";
import { Link, useLocation } from "react-router-dom";

export default function NavBar() {
  const [navbar, setNavbar] = useState(false);
  const location = useLocation();
  const absolutePosition = location.pathname === "/";

  return (
    <nav
      className={
        absolutePosition
          ? "absolute z-10 w-full bg-black md:bg-transparent"
          : "w-full bg-black"
      }
    >
      <div className="justify-between px-0 lg:max-w-10xl md:items-center md:flex md:px-8">
        <div>
          <div className="flex items-center justify-between py-3 md:py-5 md:block">
            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
              <Link to="/" className="flex flex-shrink-0 items-center">
                {/* <img
                  className="block w-52 h-auto lg:hidden"
                  src={Logo}
                  alt="Alamat Logo"
                /> */}
                <img
                  className="w-64 h-auto lg:block"
                  src={Logo}
                  alt="Alamat Logo"
                />
              </Link>
            </div>
            <div className="md:hidden">
              <button
                className="mr-4 p-2 text-yellow-700 rounded-md outline-none focus:border-gray-400 focus:border"
                onClick={() => setNavbar(!navbar)}
              >
                {navbar ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <div>
          <div
            className={`flex justify-self-center text-yellow-700 pb-3 mt-8 mx-4 md:block md:pb-0 md:mt-0 ${
              navbar ? "flex" : "hidden"
            }`}
          >
            <ul
              id="nav"
              className="items-center justify-center md:flex md:space-x-6"
            >
              <li>
                <Link className=" lg:text-3xl md:text-xl" to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link className=" lg:text-3xl md:text-xl" to="/books">
                  Books
                </Link>
              </li>
              <li>
                <Link className=" lg:text-3xl md:text-xl" to="/author">
                  Author
                </Link>
              </li>
              <li>
                <Link className=" lg:text-3xl md:text-xl" to="/maps">
                  Maps
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}
