import React, { useContext } from "react";
import { AppContext } from "./context/context";

import HTMLFlipBook from "react-pageflip";
import MapsDB from "./MapsDB";

export default function MyBook() {
  const { bookNumber } = useContext(AppContext);

  const maps = MapsDB.maps;
  const bookPages = maps[bookNumber].bookImages;
  return (
    <HTMLFlipBook
      width={355}
      height={550}
      size="fixed"
      minWidth={315}
      maxWidth={550}
      minHeight={400}
      maxHeight={733}
      maxShadowOpacity={0.5}
      mobileScrollSupport={true}
    >
      {bookPages.map((page, i) => {
        return (
          <div
            key={i}
            className={
              page.title === "Cover"
                ? "h-full"
                : "bg-white text-center p-4 pb-8 h-full"
            }
          >
            <img
              className="h-full object-contain w-full"
              src={page.image}
              alt={page.title}
            />
            {page.title !== "Cover" && <p className="-my-2">{page.title}</p>}
            {page.title !== "Cover" && page.title!== '' && <a href={page.image} target='_blank'>view image</a>}
            
          </div>
        );
      })}
    </HTMLFlipBook>
  );
}
